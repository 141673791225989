'use client'

import { useEffect, useState } from 'react'
import { PageType } from 'types/analytics'

const jsSrc = process.env.NEXT_PUBLIC_DYNATRACE_JS

export function DynatraceNotFound(): React.ReactNode {
	const [loaded, setLoaded] = useState(false)
	useEffect(() => {
		if (jsSrc && !loaded) {
			const script = document.createElement('script')
			script.src = jsSrc
			script.async = true
			script.crossOrigin = 'anonymous'
			script.onload = () => {
				setLoaded(true)
			}

			document.body.appendChild(script)
		}
	}, [])

	useEffect(() => {
		if (jsSrc && loaded) {
			window.dtrum.enable()
			window.dtrum.enableManualPageDetection()
			window.dtrum.setPage({
				name: window.location.pathname + window.location.search,
				group: PageType.NotFound,
			})
		}
	}, [loaded])

	return null
}
